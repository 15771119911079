import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { Button } from './Button'
import { LinkHubspot } from './LinkHubspot'
import knowledgeWelcomeMessage from '../Data/knowledgeWelcomeMessage.json'
const AppknowledgeWelcomeWapper = styled.div`
  max-width: 1350px;
  margin: auto;
  .WelcomeMessageSection {
    padding: 150px 10px 0px 10px;
  }
  .WelcomeOnePara {
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: left;
  }
  .WelcomeSecondPara {
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: left;
    padding-top: 50px;
  }
  .WelcomeButtonSection {
    padding: 50px;
    display: flex;
    justify-content: center;
  }
`
export const AppknowledgeWelcome = () => {
  return (
    <AppknowledgeWelcomeWapper>
      {knowledgeWelcomeMessage.map((data, index) => {
        return (
          <div key={index} className="WelcomeMessageSection">
            <ReUsePtag para={data.ParaOne} paraStyle="WelcomeOnePara" />
            <ReUsePtag para={data.ParaTwo} paraStyle="WelcomeSecondPara" />
            <div className="WelcomeButtonSection">
              <LinkHubspot to="/contactus">
                <span className="sr-only">Jump to Contact Us</span>
                <Button cta={data.ButtonName} label="Contact Us" />
              </LinkHubspot>
            </div>
          </div>
        )
      })}
    </AppknowledgeWelcomeWapper>
  )
}
