import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { Button } from './Button'
import { ReUseHTwotag } from './ReUseHTwoTag'
import knowledgeWelcomeVideo from '../Data/knowledgeWelcomeVideo.json'
import { ReUseAnchorTag } from './ReUseAnchorTag'
// export interface IFrame {
//   allow: any
//   frameborder: any
//   allowfullscreen: any
// }
// const IFrame: IFrame = {
//   allow: 'autoplay; fullscreen',
//   frameborder: '0',
//   allowfullscreen: '',
// }
const AppknowledgeWelcomeHomeWapper = styled.div`
  max-width: 1350px;
  margin: auto;
  .WelcomeMessageSection {
    padding: 0px 10px 50px 10px;
  }
  .WelcomeOnePara {
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: left;
  }
  .WelcomeHomeHeading {
    text-align: left;
    padding-bottom: 30px;
  }
  .WelcomeButtonSection {
    padding: 50px;
    display: flex;
    justify-content: center;
    @media (max-width: 1090px) {
      padding: 20px;
    }
  }
  video:focus {
    outline: none;
  }
  .VideoControls {
    width: 1050px;
    height: 600px;
    @media (max-width: 1090px) {
      width: 750px;
      height: 750px;
    }
    @media (max-width: 650px) {
      width: 400px;
      height: 400px;
    }
  }
  .WelcomeLogingButton {
    display: flex;
    justify-content: center;
    padding: 0px 0px 50px 0px;
  }
`
export const AppknowledgeWelcomeHome = () => {
  return (
    <AppknowledgeWelcomeHomeWapper>
      {knowledgeWelcomeVideo.map((data, index) => {
        return (
          <div className="WelcomeMessageSection" key={index}>
            <ReUseHTwotag Heading={data.Title} HeadingStyle="WelcomeHomeHeading" />
            <ReUsePtag para={data.Para} paraStyle="WelcomeOnePara" />
            <div className="WelcomeButtonSection">
              <iframe src={data.VideoPath} className="VideoControls" allow="autoplay; fullscreen" />
            </div>
          </div>
        )
      })}
      <div className="WelcomeLogingButton">
        <ReUseAnchorTag
          href="https://us1.raindrop.com/"
          target="_blank"
          rel="noopener noreferrer"
          anchorStyle="WelcomeLogin"
        >
          <Button cta="Login" label="Contact Us" />
        </ReUseAnchorTag>
      </div>
    </AppknowledgeWelcomeHomeWapper>
  )
}
